/* eslint-disable react/jsx-newline */
import { AppBar, Button, Menu, MenuItem, Toolbar } from '@material-ui/core';
import { AccountCircle, ArrowLeft } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import logo from '../../assets/ypsilon_logo.png';
import { logoutUser } from '../../redux/auth/auth.actions';
import { selectLoggedUser } from '../../redux/auth/auth.selectors';
import { routes } from '../../utils';
import useStyles from './HomeBar.styles';

function HomeBar() {
  const dispatch = useDispatch();
  let match = useRouteMatch({ exact: true, path: routes.byId.students.path });
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector(selectLoggedUser);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleLogout() {
    setAnchorEl(null);
    dispatch(logoutUser());
  }

  function handleBack() {
    history.push(routes.byId.students.generatePath());
  }

  function handleProfil() {
    setAnchorEl(null);
    history.push(routes.byId.profil.generatePath());
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar} disableGutters>
        <div style={{ display: 'flex' }}>
          {!Boolean(match) && (
            <Button
              onClick={handleBack}
              startIcon={<ArrowLeft />}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              Menu
            </Button>
          )}
          <span>
            <img alt='logo' className={classes.logo} src={logo} />
          </span>
        </div>
        <Button onClick={handleClick} startIcon={<AccountCircle />}>
          {user?.name || user?.email || 'Profil'}
        </Button>
        <Menu
          anchorEl={anchorEl}
          id='simple-menu'
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuItem onClick={handleProfil}>Profil</MenuItem>
          <MenuItem onClick={handleLogout}>Odhlásiť</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default HomeBar;
