import PropTypes from 'prop-types';
import React from 'react';

import Notification from './Notification.component';

const NotificationContainer = ({ notifications, onClick }) => {
  return notifications.map(({ id, message, severity, timeout }) => (
    <Notification
      id={id}
      key={id}
      message={message}
      onClick={onClick}
      severity={severity}
      timeout={timeout}
    />
  ));
};

NotificationContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationContainer;
