/* eslint-disable react/jsx-newline */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { finishRegistration } from '../../../../redux/admin/admin.actions';

const INITIAL_STATE = {
  billingAddr: '',
  payment: 'propagacna_cinnost',
};

const FinishRegistrationDialog = ({ email, onClose, open, uid }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);
  const [checkedAddr, setCheckedAddr] = useState(false);

  function handleCheckedAddr(event) {
    setCheckedAddr(event.target.checked);
  }

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  function handleSubmit() {
    let fin = { ...state, email, uid }; //email a uid
    if (checkedAddr) {
      fin['billingAddr'] = 'rovnaka';
    }
    dispatch(finishRegistration(fin));
    onClose();
  }

  useEffect(() => {
    setState(INITIAL_STATE);
    setCheckedAddr(false);
  }, [open]);

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
    >
      <DialogTitle id='form-dialog-title'>
        <b>3.KROK:</b> ÚHRADA ŠTARTOVNÉHO + VAŠA ODMENA
      </DialogTitle>

      <DialogContent dividers>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Vyberte spôsob úhrady:</FormLabel>

          <RadioGroup
            aria-label='payment'
            name='payment'
            onChange={handleChange('payment')}
            value={state.payment}
          >
            <FormControlLabel
              control={<Radio />}
              label={
                <p>
                  <b>PROPAGAČNÁ ČINNOSŤ</b>
                </p>
              }
              value='propagacna_cinnost'
            />

            <FormControlLabel
              control={<Radio />}
              label={
                <p>
                  <b>DOHODA O VYKONANÍ PRÁCE</b>
                </p>
              }
              value='dohoda'
            />
          </RadioGroup>
        </FormControl>

        <TextField
          autoComplete='off'
          autoFocus
          disabled={checkedAddr}
          fullWidth
          id='billingAddr'
          label='Fakturačná adresa'
          margin='dense'
          onChange={handleChange('billingAddr')}
          style={{ marginBottom: 20 }}
          value={state.billingAddr}
        />

        <p>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedAddr}
                name='checkedAddr'
                onChange={handleCheckedAddr}
              />
            }
            label='Korešpondenčná adresa je rovnaká ako fakturačná
        adresa'
          />
        </p>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Zrušiť</Button>

        <Button
          color='primary'
          disabled={
            !Boolean(
              (state.billingAddr.length >= 1 || checkedAddr) &&
                state.payment.length >= 1
            )
          }
          onClick={handleSubmit}
          size='small'
          style={{ color: 'white' }}
          variant='contained'
        >
          Uzavrieť registráciu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishRegistrationDialog;
