import { createReducer } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import {
  createNotification,
  removeNotification,
} from './notifications.actions';

const initialState = {};

export default createReducer(initialState, {
  [createNotification](state, action) {
    state[nanoid(6)] = action.payload;
  },
  [removeNotification](state, action) {
    delete state[action.payload];
  },
});
