import { createReducer } from '@reduxjs/toolkit';

import { getCoordinatorsReceived } from './admin.actions';

const initialState = {
  coordinators: {},
  statistics: {
    finished: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      all: 0,
    },
    unfinished: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      all: 0,
    },
  },
};

export default createReducer(initialState, {
  [getCoordinatorsReceived](state, action) {
    state.coordinators = action.payload;
    let coordinators = action.payload;
    state.statistics = initialState.statistics;
    if (coordinators) {
      let statistics_var = {
        finished: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          all: 0,
        },
        unfinished: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          all: 0,
        },
      };
      Object.values(coordinators).forEach((coordinator) => {
        if (coordinator?.students) {
          Object.values(coordinator?.students).forEach((student) => {
            if (coordinator?.finished) {
              statistics_var.finished[student?.classroom] += 1;
              statistics_var.finished.all += 1;
            } else {
              statistics_var.unfinished[student?.classroom] += 1;
              statistics_var.unfinished.all += 1;
            }
          });
        }
      });
      state.statistics = statistics_var;
    }
  },
});
