import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
  },
  logo: {
    height: 42,
    marginLeft: 10,
  },
  toolbar: {
    '& > *': {
      color: theme.palette.grey[700],
    },
    justifyContent: 'space-between',
    paddingRight: 20,
  },
}));
