/* eslint-disable react/jsx-newline */
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import logo from '../../assets/ypsilon_logo.png';
import { registerUser } from '../../redux/auth/auth.actions';
import { createNotification } from '../../redux/notifications/notifications.actions';
import { routes } from '../../utils';
import useStyles from './SignUp.styles';

const INITIAL_STATE = {
  checked: false,
  city: '',
  email: '',
  firstname: '',
  lastname: '',
  password: '',
  phone: '',
  postalCode: '',
  repeatPassword: '',
  schoolName: '',
  street: '',
  title: '',
};

const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);

  function handleSubmit() {
    //check values (e.g. passwords)
    if (state.password !== state.repeatPassword) {
      dispatch(
        createNotification({
          message: `auth/different-passwords: Heslá sa nezhodujú`,
          severity: 'error',
        })
      );
      return;
    }
    if (!state.checked) {
      dispatch(
        createNotification({
          message: `auth/not-checked: Neodsúhlasené pravidlá súťaže`,
          severity: 'error',
        })
      );
      return;
    }
    dispatch(
      registerUser({
        city: state.city,
        email: state.email,
        firstname: state.firstname,
        lastname: state.lastname,
        name: `${state.firstname} ${state.lastname}, ${state.title}`,
        password: state.password,
        phone: state.phone,
        postalCode: state.postalCode,
        schoolName: state.schoolName,
        street: state.street,
        title: state.title,
      })
    );
  }

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  const handleChecked = useCallback(() => {
    setState((state) => ({ ...state, checked: !state.checked }));
  }, [setState]);

  return (
    <div className={classes.root}>
      <Container fixed maxWidth='md'>
        <Paper className={classes.container} variant='outlined'>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <img alt='logo' className={classes.logo} src={logo} />
            </Grid>

            <Grid item>
              <div className={classes.form}>
                <h2>Registrácia</h2>

                <Typography variant='h6'>Koordinátor</Typography>

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Titul'
                  name='title'
                  onChange={handleChange('title')}
                  required
                  size='small'
                  value={state.title}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Meno'
                  name='firstname'
                  onChange={handleChange('firstname')}
                  required
                  size='small'
                  value={state.firstname}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Priezvisko'
                  name='lastname'
                  onChange={handleChange('lastname')}
                  required
                  size='small'
                  value={state.lastname}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Telefónne číslo'
                  name='phone'
                  onChange={handleChange('phone')}
                  required
                  size='small'
                  value={state.phone}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Email'
                  name='email'
                  onChange={handleChange('email')}
                  required
                  size='small'
                  value={state.email}
                />

                <Typography variant='h6'>Heslo</Typography>

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Heslo'
                  name='password'
                  onChange={handleChange('password')}
                  required
                  size='small'
                  type='password'
                  value={state.password}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Heslo znova'
                  name='repeatPassword'
                  onChange={handleChange('repeatPassword')}
                  required
                  size='small'
                  type='password'
                  value={state.repeatPassword}
                />

                <Typography variant='h6'>Informácie o škole</Typography>

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Názov školy'
                  name='schoolName'
                  onChange={handleChange('schoolName')}
                  required
                  size='small'
                  value={state.schoolName}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Ulica a číslo'
                  name='street'
                  onChange={handleChange('street')}
                  required
                  size='small'
                  value={state.street}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Mesto'
                  name='city'
                  onChange={handleChange('city')}
                  required
                  size='small'
                  value={state.city}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='PSČ'
                  name='postalCode'
                  onChange={handleChange('postalCode')}
                  required
                  size='small'
                  value={state.postalCode}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checked}
                      color='primary'
                      name='checked'
                      onChange={handleChecked}
                    />
                  }
                  label={
                    <>
                      Súhlasím s{' '}
                      <Link
                        href='https://ypsilonsutaz.sk/docs/pravidla_ypsilon.pdf'
                        rel='noopener'
                        target='_blank'
                      >
                        pravidlami súťaže
                      </Link>
                    </>
                  }
                />

                <Button
                  className={classes.button}
                  color='primary'
                  onClick={handleSubmit}
                  type='submit'
                  variant='contained'
                >
                  Registrovať
                </Button>
              </div>
            </Grid>

            <Grid className={classes.registrationLinkGrid} item>
              {`Máte už účet? `}

              <Link
                component={RouterLink}
                to={routes.byId.login.generatePath()}
              >
                Prihláste sa
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default SignUp;
