import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLoadingFlags } from '../../redux/loading/loading.selectors';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function SimpleBackdrop() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [getUserLoading] = useSelector(selectLoadingFlags('GET_USER'));
  const [getCoordinatorLoading] = useSelector(
    selectLoadingFlags('GET_COORDINATOR')
  );

  useEffect(() => {
    setOpen(Boolean(getUserLoading) || Boolean(getCoordinatorLoading));
  }, [getUserLoading, getCoordinatorLoading]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}
