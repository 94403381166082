import { lighten, makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  table: {
    '& .theme--Closed': {
      '&:hover': {
        backgroundColor: lighten(theme.palette.warning.main, 0.5),
      },
      backgroundColor: lighten(theme.palette.warning.main, 0.6),
      cursor: 'pointer',
    },
    '& .theme--Open': {
      cursor: 'pointer',
    },
  },
}));
