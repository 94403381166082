/* eslint-disable react/jsx-newline */
import {
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import logo from '../../assets/ypsilon_logo.png';
import { resetPassword } from '../../redux/auth/auth.actions';
import { routes } from '../../utils';
import useStyles from './PasswordReset.styles';

const INITIAL_STATE = {
  email: '',
};

const PasswordReset = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_STATE);
  const [emailSent, setEmailSent] = useState(false);

  function handleSubmit() {
    dispatch(resetPassword({ email: state.email })).then(() => {
      setEmailSent(true);
    });
  }

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  return (
    <div className={classes.root}>
      <Container fixed maxWidth='xs'>
        <Paper className={classes.container} variant='outlined'>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <img alt='logo' className={classes.logo} src={logo} />
            </Grid>

            <Grid item>
              <div className={classes.form}>
                <h2>Zabudol som heslo</h2>
                {emailSent ? (
                  <>Poslali sme vám email s návodom na resetovanie hesla</>
                ) : (
                  <>
                    <TextField
                      className={classes.formInput}
                      fullWidth
                      label='Email'
                      name='email'
                      onChange={handleChange('email')}
                      required
                      size='small'
                      value={state.email}
                    />

                    <Button
                      className={classes.button}
                      color='primary'
                      onClick={handleSubmit}
                      type='submit'
                      variant='contained'
                    >
                      Resetovať heslo
                    </Button>
                  </>
                )}
              </div>
            </Grid>

            <Grid className={classes.registrationLinkGrid} item>
              <span>Späť na</span>{' '}
              <Link
                component={RouterLink}
                to={routes.byId.login.generatePath()}
              >
                prihlásenie
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default PasswordReset;
