import { createAction } from '@reduxjs/toolkit';
import { push, ref, remove, set, update } from 'firebase/database';

import { database } from '../../firebase.utils';
import { createNotification } from '../notifications/notifications.actions';

export const [
  getCoordinatorsStarted,
  getCoordinatorsReceived,
  getCoordinatorsFailed,
] = [
  createAction('GET_COORDINATORS_STARTED'),
  createAction('GET_COORDINATORS_RECEIVED'),
  createAction('GET_COORDINATORS_FAILED'),
];

export const [
  reopenRegistrationStarted,
  reopenRegistrationReceived,
  reopenRegistrationFailed,
] = [
  createAction('REOPEN_REGISTRATION_STARTED'),
  createAction('REOPEN_REGISTRATION_RECEIVED'),
  createAction('REOPEN_REGISTRATION_FAILED'),
];

export const reopenRegistration =
  ({ uid }) =>
  (dispatch) => {
    dispatch(reopenRegistrationStarted());
    const updates = {};
    updates['/finished'] = null;
    updates['/billing'] = null;
    update(ref(database, '/koordinator/' + uid), updates)
      .then(() => {
        dispatch(reopenRegistrationReceived());
        dispatch(
          createNotification({
            message: `Registrácia bola otvorená`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(reopenRegistrationFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [addStudentStarted, addStudentReceived, addStudentFailed] = [
  createAction('ADMIN_ADD_STUDENT_STARTED'),
  createAction('ADMIN_ADD_STUDENT_RECEIVED'),
  createAction('ADMIN_ADD_STUDENT_FAILED'),
];

export const addStudent =
  (cid, { classroom, firstname, lastname }) =>
  (dispatch) => {
    dispatch(addStudentStarted());
    set(push(ref(database, '/koordinator/' + cid + '/students')), {
      classroom,
      firstname,
      lastname,
    })
      .then(() => {
        dispatch(addStudentReceived());
        dispatch(
          createNotification({
            message: `Žiak bol pridaný`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(addStudentFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [
  removeStudentStarted,
  removeStudentReceived,
  removeStudentFailed,
] = [
  createAction('ADMIN_REMOVE_STUDENT_STARTED'),
  createAction('ADMIN_REMOVE_STUDENT_RECEIVED'),
  createAction('ADMIN_REMOVE_STUDENT_FAILED'),
];

export const removeStudent = (cid, sid) => (dispatch) => {
  dispatch(removeStudentStarted());
  remove(ref(database, '/koordinator/' + cid + '/students/' + sid))
    .then(() => {
      dispatch(removeStudentReceived());
      dispatch(
        createNotification({
          message: `Žiak bol odstránený`,
          severity: 'warning',
          timeout: 5000,
        })
      );
    })
    .catch((error) => {
      dispatch(removeStudentFailed());
      dispatch(
        createNotification({
          message: `${error.code}: ${error.message}`,
          severity: 'error',
        })
      );
    });
};

export const [
  updateStudentStarted,
  updateStudentReceived,
  updateStudentFailed,
] = [
  createAction('ADMIN_UPDATE_STUDENT_STARTED'),
  createAction('ADMIN_UPDATE_STUDENT_RECEIVED'),
  createAction('ADMIN_UPDATE_STUDENT_FAILED'),
];

export const updateStudent =
  (cid, { classroom, firstname, lastname, percentage, points, sid }) =>
  (dispatch) => {
    dispatch(updateStudentStarted());
    const updates = {};
    updates['/firstname'] = firstname;
    updates['/lastname'] = lastname;
    updates['/classroom'] = classroom;
    updates['/percentage'] = percentage;
    updates['/points'] = points;
    update(ref(database, '/koordinator/' + cid + '/students/' + sid), updates)
      .then(() => {
        dispatch(updateStudentReceived());
        dispatch(
          createNotification({
            message: `Žiak bol zmenený`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(updateStudentFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [
  finishRegistrationStarted,
  finishRegistrationReceived,
  finishRegistrationFailed,
] = [
  createAction('ADMIN_FINISH_REGISTRATION_STARTED'),
  createAction('ADMIN_FINISH_REGISTRATION_RECEIVED'),
  createAction('ADMIN_FINISH_REGISTRATION_FAILED'),
];

export const finishRegistration =
  ({ billingAddr, email, payment, uid }) =>
  (dispatch) => {
    dispatch(finishRegistrationStarted());
    const updates = {};
    updates['/finished'] = true;
    updates['/billing/billingAddr'] = billingAddr;
    updates['/billing/finished'] = true;
    updates['/billing/email'] = email;
    updates['/billing/payment'] = payment;
    update(ref(database, '/koordinator/' + uid), updates)
      .then(() => {
        dispatch(finishRegistrationReceived());
        dispatch(
          createNotification({
            message: `Registrácia bola uzavretá`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(finishRegistrationFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [reopenAllStarted, reopenAllReceived, reopenAllFailed] = [
  createAction('ADMIN_REOPEN_ALL_STARTED'),
  createAction('ADMIN_REOPEN_ALL_RECEIVED'),
  createAction('ADMIN_REOPEN_ALL_FAILED'),
];

export const reopenAll = (coordinators) => (dispatch) => {
  dispatch(reopenAllStarted());
  const updates = {};

  Object.keys(coordinators).forEach((cid) => {
    updates['' + cid + '/finished'] = null;
    updates['' + cid + '/billing'] = null;
    updates['' + cid + '/students'] = null;
  });

  update(ref(database, '/koordinator/'), updates)
    .then(() => {
      dispatch(finishRegistrationReceived());
      dispatch(
        createNotification({
          message: `Otvorená registrácia`,
          severity: 'success',
        })
      );
    })
    .catch((error) => {
      dispatch(finishRegistrationFailed());
      dispatch(
        createNotification({
          message: `${error.code}: ${error.message}`,
          severity: 'error',
        })
      );
    });
};
