import {
  Box,
  Button,
  Container,
  createTheme,
  IconButton,
  Paper,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DataGrid, skSK } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  removeStudent,
  reopenRegistration,
} from '../../../redux/admin/admin.actions';
import { selectCoordinators } from '../../../redux/admin/admin.selectors';
import AddStudentDialog from './AddStudentDialog/AddStudentDialog.component';
import useStyles from './Detail.styles';
import FinishRegistrationDialog from './FinishRegistrationDialog/FinishRegistrationDialog.component';
import UpdateStudentDialog from './UpdateStudentDialog/UpdateStudentDialog.component';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const INITIAL_COORDINATOR = null;

const DownloadExcel = ({ dataset }) => {
  return (
    <ExcelFile
      element={
        <IconButton
          aria-label='download excel'
          color='primary'
          component='span'
        >
          <GetAppIcon />
        </IconButton>
      }
      filename='ziaci'
    >
      <ExcelSheet data={dataset} name='Koordinator'>
        <ExcelColumn label='Meno' value='firstname' />

        <ExcelColumn label='Priezvisko' value='lastname' />

        <ExcelColumn label='Trieda' value='classroom' />

        <ExcelColumn label='Body' value='points' />

        <ExcelColumn label='Percentá' value='percentage' />
      </ExcelSheet>
    </ExcelFile>
  );
};

const Detail = () => {
  const classes = useStyles();
  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    skSK
  );
  const dispatch = useDispatch();
  let { coordinatorId } = useParams();
  const [coordinator, setCoordinator] = useState(INITIAL_COORDINATOR);
  const [openAddStudentDialog, setOpenAddStudentDialog] = useState(false);
  const [openUpdateStudentDialog, setOpenUpdateStudentDialog] = useState(false);
  const [updatedStudent, setUpdatedStudent] = useState(null);
  const [updatedStudentSid, setUpdatedStudentSid] = useState(null);
  const [openFinishRegistrationDialog, setOpenFinishRegistrationDialog] =
    useState(false);
  const coordinators = useSelector(selectCoordinators);

  function handleReopenRegistration() {
    dispatch(reopenRegistration({ uid: coordinatorId }));
  }

  function handleClickOpenAddStudentDialog() {
    setOpenAddStudentDialog(true);
  }

  function handleCloseAddStudentDialog() {
    setOpenAddStudentDialog(false);
  }

  const handleRemoveStudent = useCallback(
    (sid) => {
      return () => {
        dispatch(removeStudent(coordinatorId, sid));
      };
    },
    [coordinatorId, dispatch]
  );

  const handleOpenUpdateStudentDialog = (sid) => {
    return () => {
      setUpdatedStudentSid(sid);
      setUpdatedStudent(coordinator?.students[sid]);
      setOpenUpdateStudentDialog(true);
    };
  };

  function handleCloseUpdateStudentDialog() {
    setOpenUpdateStudentDialog(false);
    setUpdatedStudent(null);
    setUpdatedStudentSid(null);
  }

  function handleClickOpenFinishRegistrationDialog() {
    setOpenFinishRegistrationDialog(true);
  }

  function handleCloseFinishRegistrationDialog() {
    setOpenFinishRegistrationDialog(false);
  }

  const getStudentsOfCoordinator = useCallback(() => {
    return coordinator?.students
      ? Object.keys(coordinator?.students).map((key) => ({
          ...coordinator?.students[key],
          id: key,
        }))
      : [];
  }, [coordinator]);

  const columns = [
    { field: 'firstname', flex: 1, headerName: 'Meno', minWidth: 150 },
    { field: 'lastname', flex: 1, headerName: 'Priezvisko', minWidth: 150 },
    { field: 'classroom', flex: 1, headerName: 'Ročník', minWidth: 150 },
    { field: 'points', flex: 1, headerName: 'Body', minWidth: 150 },
    { field: 'percentage', flex: 1, headerName: 'Percentá', minWidth: 150 },
    {
      field: 'id',
      filterable: false,
      flex: 1,
      headerName: 'Akcie',
      minWidth: 150,
      renderCell: ({ value }) => (
        <strong>
          <IconButton
            color='secondary'
            onClick={handleRemoveStudent(value)}
            size='small'
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            color='secondary'
            onClick={handleOpenUpdateStudentDialog(value)}
            size='small'
            style={{ marginLeft: 16 }}
          >
            <EditIcon />
          </IconButton>
        </strong>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    setCoordinator(coordinators[coordinatorId] || null);
  }, [coordinators, coordinatorId]);

  return (
    <Container className={classes.container}>
      <AddStudentDialog
        cid={coordinatorId}
        onClose={handleCloseAddStudentDialog}
        open={openAddStudentDialog}
      />

      <UpdateStudentDialog
        cid={coordinatorId}
        onClose={handleCloseUpdateStudentDialog}
        open={openUpdateStudentDialog}
        sid={updatedStudentSid}
        student={updatedStudent}
      />

      <FinishRegistrationDialog
        email={coordinator?.email}
        onClose={handleCloseFinishRegistrationDialog}
        open={openFinishRegistrationDialog}
        uid={coordinatorId}
      />

      <Typography variant='h4'>{`${coordinator?.title} ${coordinator?.firstname} ${coordinator?.lastname}`}</Typography>

      <Typography>{`${coordinator?.createdAt}`}</Typography>

      <Typography variant='h6'>{`${coordinator?.schoolName}`}</Typography>

      <Typography>{`${coordinator?.street}`}</Typography>

      <Typography>{`${coordinator?.city} ${coordinator?.postalCode}`}</Typography>

      <Typography>{`${coordinator?.email}`}</Typography>

      <Typography>{`${coordinator?.phone}`}</Typography>

      <Button
        color='primary'
        onClick={handleClickOpenAddStudentDialog}
        variant='text'
      >
        Pridať žiaka
      </Button>

      {coordinator?.finished ? (
        <Button
          color='primary'
          onClick={handleReopenRegistration}
          variant='contained'
        >
          Otvoriť registráciu
        </Button>
      ) : (
        <Button
          color='primary'
          onClick={handleClickOpenFinishRegistrationDialog}
          variant='text'
        >
          Uzavrieť registráciu
        </Button>
      )}

      <Box
        component={Paper}
        style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
      >
        <DownloadExcel
          dataset={
            coordinator && coordinator.students
              ? Object.keys(coordinator.students).map((key) => ({
                  ...coordinator.students[key],
                  id: key,
                }))
              : []
          }
        />

        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            columns={columns}
            disableSelectionOnClick
            rows={getStudentsOfCoordinator()}
          />
        </ThemeProvider>
      </Box>
    </Container>
  );
};

export default Detail;
