import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';

const Notification = ({ id, message, onClick, severity, timeout }) => {
  const DEFAULT_TIMEOUT = 5000;
  const duration = timeout
    ? timeout
    : severity === 'info' ||
      severity === 'success' ||
      severity === 'warning' ||
      severity === 'error'
    ? DEFAULT_TIMEOUT
    : null;
  function handleClick(e, reason) {
    if (reason !== 'clickaway') {
      onClick(id);
    }
  }

  return (
    <MuiSnackbar autoHideDuration={duration || null} onClose={handleClick} open>
      <MuiAlert onClose={handleClick} severity={severity}>
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  timeout: PropTypes.number,
};

export default Notification;
