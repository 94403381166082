import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateCoordinator } from '../../redux/coordinator/coordinator.actions';
import { selectCoordinator } from '../../redux/coordinator/coordinator.selectors';
import useStyles from './Profil.styles';

const INITIAL_STATE = {
  city: '',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  postalCode: '',
  schoolName: '',
  street: '',
  title: '',
};

const Profil = () => {
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const coordinator = useSelector(selectCoordinator);

  useEffect(() => {
    setState(coordinator);
  }, [coordinator]);

  function handleSubmit() {
    dispatch(updateCoordinator(state));
  }

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  return (
    <Paper className={classes.container} variant='outlined'>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <div className={classes.form}>
            <h2>Zmena údajov</h2>

            <Typography variant='h6'>Koordinátor</Typography>

            <TextField
              className={classes.formInput}
              fullWidth
              label='Titul'
              name='title'
              onChange={handleChange('title')}
              required
              size='small'
              value={state.title}
            />

            <TextField
              className={classes.formInput}
              fullWidth
              label='Meno'
              name='firstname'
              onChange={handleChange('firstname')}
              required
              size='small'
              value={state.firstname}
            />

            <TextField
              className={classes.formInput}
              fullWidth
              label='Priezvisko'
              name='lastname'
              onChange={handleChange('lastname')}
              required
              size='small'
              value={state.lastname}
            />

            <TextField
              className={classes.formInput}
              fullWidth
              label='Telefónne číslo'
              name='phone'
              onChange={handleChange('phone')}
              required
              size='small'
              value={state.phone}
            />

            <TextField
              className={classes.formInput}
              disabled
              fullWidth
              label='Email'
              name='email'
              size='small'
              value={state.email}
            />

            <Typography variant='h6'>Informácie o škole</Typography>

            <TextField
              className={classes.formInput}
              fullWidth
              label='Názov školy'
              name='schoolName'
              onChange={handleChange('schoolName')}
              required
              size='small'
              value={state.schoolName}
            />

            <TextField
              className={classes.formInput}
              fullWidth
              label='Ulica a číslo'
              name='street'
              onChange={handleChange('street')}
              required
              size='small'
              value={state.street}
            />

            <TextField
              className={classes.formInput}
              fullWidth
              label='Mesto'
              name='city'
              onChange={handleChange('city')}
              required
              size='small'
              value={state.city}
            />

            <TextField
              className={classes.formInput}
              fullWidth
              label='PSČ'
              name='postalCode'
              onChange={handleChange('postalCode')}
              required
              size='small'
              value={state.postalCode}
            />

            <Button
              className={classes.button}
              color='primary'
              onClick={handleSubmit}
              type='submit'
              variant='contained'
            >
              Uložiť zmeny
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Profil;
