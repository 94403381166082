import { createReducer } from '@reduxjs/toolkit';

import { getCoordinatorReceived } from './coordinator.actions';

const initialState = {
  coordinator: null,
};

export default createReducer(initialState, {
  [getCoordinatorReceived](state, action) {
    state.coordinator = action.payload;
  },
});
