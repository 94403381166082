import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { selectCoordinator } from '../../redux/coordinator/coordinator.selectors';
import { routes } from '../../utils';
import Admin from '../Admin/Admin.component';
import Detail from '../Admin/Detail/Detail.component';
import HomeBar from '../HomeBar/HomeBar.component';
import Profil from '../Profil/Profil.component';
import Students from '../Students/Students.component';

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}));

const Home = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const coordinator = useSelector(selectCoordinator);

  return (
    <>
      <HomeBar />

      <div className={classes.offset} />

      <Switch>
        <Redirect exact path={path} to={routes.byId.students.generatePath()} />

        <Route
          component={!Boolean(coordinator?.admin) ? Students : Admin}
          exact
          path={routes.byId.students.path}
        />

        <Route
          component={!Boolean(coordinator?.admin) ? Students : Detail}
          exact
          path={routes.byId.coordinatorDetail.path}
        />

        <Route component={Profil} exact path={routes.byId.profil.path} />
      </Switch>
    </>
  );
};

export default Home;
