import {
  Box,
  Button,
  Container,
  createTheme,
  IconButton,
  Link,
  Paper,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { DataGrid, skSK } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeStudent } from '../../redux/coordinator/coordinator.actions';
import { selectCoordinator } from '../../redux/coordinator/coordinator.selectors';
import AddStudentDialog from './AddStudentDialog/AddStudentDialog.component';
import FinishRegistrationDialog from './FinishRegistrationDialog/FinishRegistrationDialog.component';
import useStyles from './Students.styles';
import UpdateStudentDialog from './UpdateStudentDialog/UpdateStudentDialog.component';

const Students = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    skSK
  );

  const coordinator = useSelector(selectCoordinator);

  const [openAddStudentDialog, setOpenAddStudentDialog] = useState(false);
  const [openUpdateStudentDialog, setOpenUpdateStudentDialog] = useState(false);
  const [updatedStudent, setUpdatedStudent] = useState(null);
  const [updatedStudentSid, setUpdatedStudentSid] = useState(null);
  const [openFinishRegistrationDialog, setOpenFinishRegistrationDialog] =
    useState(false);
  const [finished, setFinished] = useState(false);

  function handleClickOpenAddStudentDialog() {
    setOpenAddStudentDialog(true);
  }

  function handleCloseAddStudentDialog() {
    setOpenAddStudentDialog(false);
  }

  function handleClickOpenFinishRegistrationDialog() {
    setOpenFinishRegistrationDialog(true);
  }

  function handleCloseFinishRegistrationDialog() {
    setOpenFinishRegistrationDialog(false);
  }

  const handleOpenUpdateStudentDialog = (sid) => {
    return () => {
      setUpdatedStudentSid(sid);
      setUpdatedStudent(coordinator?.students[sid]);
      setOpenUpdateStudentDialog(true);
    };
  };

  function handleCloseUpdateStudentDialog() {
    setOpenUpdateStudentDialog(false);
    setUpdatedStudent(null);
    setUpdatedStudentSid(null);
  }

  const handleRemoveStudent = useCallback(
    (sid) => {
      return () => {
        dispatch(removeStudent(sid));
      };
    },
    [dispatch]
  );

  const columns = [
    { field: 'firstname', flex: 1, headerName: 'Meno', minWidth: 150 },
    { field: 'lastname', flex: 1, headerName: 'Priezvisko', minWidth: 150 },
    { field: 'classroom', flex: 1, headerName: 'Ročník', minWidth: 150 },
    { field: 'points', flex: 1, headerName: 'Body', minWidth: 150 },
    { field: 'percentage', flex: 1, headerName: 'Percentá', minWidth: 150 },
    {
      field: 'id',
      filterable: false,
      flex: 1,
      headerName: 'Akcie',
      hide: finished,
      minWidth: 150,
      renderCell: ({ value }) => (
        <strong>
          <IconButton
            color='secondary'
            disabled={finished}
            onClick={handleRemoveStudent(value)}
            size='small'
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            color='secondary'
            disabled={finished}
            onClick={handleOpenUpdateStudentDialog(value)}
            size='small'
            style={{ marginLeft: 16 }}
          >
            <EditIcon />
          </IconButton>
        </strong>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    if (coordinator?.finished) {
      setFinished(true);
    }
  }, [coordinator]);

  return (
    <Container className={classes.container} maxWidth='lg'>
      {finished ? (
        <>
          <Typography variant='h6'>
            Ďakujeme za registráciu žiakov do súťaže YPSILON - slovina je hra.
            Vaša registrácia je týmto záväzne uzavretá, do 24 hodín Vám príde
            mailom faktúra na úhradu štartovného.
          </Typography>

          {coordinator?.billing?.payment === 'dohoda' && (
            <>
              <Typography>
                Vašu odmenu pre koordinátora získate formou dohody o vykonaní
                práce. Dohodu o vykonaní práce si stiahnite a vyplnenú a
                podpísanú nám ju prosím pošlite v 2 vyhotoveniach na adresu:
                Lexicon, s.r.o., P.O.BOX 46, 040 17 Košice.
              </Typography>

              <Link
                href='https://ypsilonsutaz.sk/docs/dohoda.pdf'
                rel='noopener'
                target='_blank'
              >
                Dohoda na stiahnutie
              </Link>
            </>
          )}

          {coordinator?.billing?.payment === 'propagacna_cinnost' && (
            <Typography>
              Vašu odmenu pre koordinátora získate formou 20% zľavy zo
              štartovného.
            </Typography>
          )}
        </>
      ) : (
        <>
          <AddStudentDialog
            onClose={handleCloseAddStudentDialog}
            open={openAddStudentDialog}
          />

          <UpdateStudentDialog
            onClose={handleCloseUpdateStudentDialog}
            open={openUpdateStudentDialog}
            sid={updatedStudentSid}
            student={updatedStudent}
          />

          <FinishRegistrationDialog
            onClose={handleCloseFinishRegistrationDialog}
            open={openFinishRegistrationDialog}
          />

          <Typography>
            1. krok: Žiakov môžete registrovať do 12.11.2024
          </Typography>

          <Button
            color='primary'
            onClick={handleClickOpenAddStudentDialog}
            variant='text'
          >
            Pridať žiaka
          </Button>
        </>
      )}

      <Box
        component={Paper}
        style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
      >
        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            columns={columns}
            disableSelectionOnClick
            rows={
              coordinator?.students
                ? Object.keys(coordinator?.students).map((key) => ({
                    ...coordinator?.students[key],
                    id: key,
                  }))
                : []
            }
          />
        </ThemeProvider>
      </Box>

      {finished ? null : (
        <>
          <Typography>
            2. krok: Záväzná registrácia žiakov do súťaže (minimálne 10 žiakov)
          </Typography>

          <Button
            color='primary'
            disabled={Boolean(
              Object.keys(coordinator?.students || {}).length < 10
            )}
            onClick={handleClickOpenFinishRegistrationDialog}
            variant='text'
          >
            Záväzná registrácia do súťaže
          </Button>
        </>
      )}
    </Container>
  );
};

export default Students;
