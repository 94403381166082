import {
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import logo from '../../assets/ypsilon_logo.png';
import { loginUser } from '../../redux/auth/auth.actions';
import { routes } from '../../utils';
import useStyles from './Login.styles';

const INITIAL_STATE = {
  email: '',
  password: '',
};

const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_STATE);

  function handleSubmit() {
    dispatch(loginUser({ email: state.email, password: state.password }));
  }

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  return (
    <div className={classes.root}>
      <Container fixed maxWidth='xs'>
        <Paper className={classes.container} variant='outlined'>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <span>
                <img alt='logo' className={classes.logo} src={logo} />
              </span>
            </Grid>

            <Grid item>
              <div className={classes.form}>
                <h2>Prihlásenie</h2>

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Email'
                  name='email'
                  onChange={handleChange('email')}
                  required
                  size='small'
                  value={state.email}
                />

                <TextField
                  className={classes.formInput}
                  fullWidth
                  label='Heslo'
                  name='password'
                  onChange={handleChange('password')}
                  required
                  size='small'
                  type='password'
                  value={state.password}
                />

                <Button
                  className={classes.button}
                  color='primary'
                  onClick={handleSubmit}
                  type='submit'
                  variant='contained'
                >
                  Prihlásiť
                </Button>
              </div>
            </Grid>

            <Grid item>
              <Link
                className={classes.link}
                component={RouterLink}
                to={routes.byId.passwordReset.generatePath()}
              >
                Zabudli ste heslo?
              </Link>
            </Grid>

            <Grid className={classes.registrationLinkGrid} item>
              {`Ešte nemáte účet? `}

              <Link
                component={RouterLink}
                to={routes.byId.signup.generatePath()}
              >
                Zaregistrujte sa
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
