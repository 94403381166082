export const routes = {
  byId: {
    coordinatorDetail: {
      generatePath: (coordinatorId) => `/home/koordinator/${coordinatorId}`,
      path: '/home/koordinator/:coordinatorId',
    },
    home: {
      generatePath: () => '/home',
      path: '/home',
    },
    login: {
      generatePath: () => '/login',
      path: '/login',
    },
    passwordReset: {
      generatePath: () => '/passwdreset',
      path: '/passwdreset',
    },
    profil: {
      generatePath: () => '/home/profil',
      path: '/home/profil',
    },
    signup: {
      generatePath: () => '/signup',
      path: '/signup',
    },
    students: {
      generatePath: () => '/home/koordinator',
      path: '/home/koordinator',
    },
  },
};
