/* eslint-disable react/jsx-newline */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { auth } from '../../../firebase.utils';
import { finishRegistration } from '../../../redux/coordinator/coordinator.actions';
import { selectCoordinator } from '../../../redux/coordinator/coordinator.selectors';

const INITIAL_STATE = {
  billingAddr: '',
  payment: 'propagacna_cinnost',
};

const FinishRegistrationDialog = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);
  const coordinator = useSelector(selectCoordinator);
  const [numStudents, setNumStudents] = useState(0);
  const [checkedAddr, setCheckedAddr] = useState(false);

  function handleCheckedAddr(event) {
    setCheckedAddr(event.target.checked);
  }

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  function handleSubmit() {
    const email = auth.currentUser.email;
    let fin = { ...state, email };
    if (checkedAddr) {
      fin['billingAddr'] = 'rovnaka';
    }
    dispatch(finishRegistration(fin));
    onClose();
  }

  useEffect(() => {
    setState(INITIAL_STATE);
    setCheckedAddr(false);
  }, [open]);

  useEffect(() => {
    setNumStudents(Object.keys(coordinator?.students || {}).length);
  }, [coordinator]);

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
    >
      <DialogTitle id='form-dialog-title'>
        <b>3.KROK:</b> ÚHRADA ŠTARTOVNÉHO + VAŠA ODMENA
      </DialogTitle>

      <DialogContent dividers>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Vyberte spôsob úhrady:</FormLabel>

          <RadioGroup
            aria-label='payment'
            name='payment'
            onChange={handleChange('payment')}
            value={state.payment}
          >
            <FormControlLabel
              control={<Radio />}
              label={
                <>
                  <p>
                    <b>PROPAGAČNÁ ČINNOSŤ:</b>
                  </p>
                  <p>
                    Ak si zvolíte tento spôsob platby, vystavíme Vám faktúru s
                    20% zľavou.
                  </p>
                  <p>
                    {numStudents} žiakov x 6,50€ štartovné v súťaži ={' '}
                    {numStudents * 6.5} € - 20% zľava (Vaša odmena{' '}
                    {numStudents * 6.5 * 0.2} €) = {numStudents * 6.5 * 0.8} €
                    (finálna suma na úhradu)
                  </p>
                </>
              }
              value='propagacna_cinnost'
            />

            <FormControlLabel
              control={<Radio />}
              label={
                <>
                  <p>
                    <b>DOHODA O VYKONANÍ PRÁCE:</b>
                  </p>
                  <p>
                    Ak si zvolíte túto možnosť, vystavíme Vám faktúru na úplnú
                    sumu (bez zľavy) a Vašu odmenu Vám poukážeme na základe
                    Dohody o vykonaní práce.
                  </p>
                  <p>
                    {numStudents} žiakov x 6.5 € štartovné ={numStudents * 6.5}{' '}
                    €
                  </p>
                  <p>
                    Vaša odmena Vám príde priamo na účet, ktorý uvediete v
                    Dohode o vykonaní práce.
                  </p>
                  <p>
                    Upozorňujeme, že odmena bude znížená o odvody do poisťovne a
                    daň z príjmu.
                  </p>
                </>
              }
              value='dohoda'
            />
          </RadioGroup>
        </FormControl>

        <TextField
          autoComplete='off'
          autoFocus
          disabled={checkedAddr}
          fullWidth
          id='billingAddr'
          label='Fakturačná adresa'
          margin='dense'
          onChange={handleChange('billingAddr')}
          style={{ marginBottom: 20 }}
          value={state.billingAddr}
        />

        <p>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedAddr}
                name='checkedAddr'
                onChange={handleCheckedAddr}
              />
            }
            label='Korešpondenčná adresa je rovnaká ako fakturačná
        adresa'
          />
        </p>
      </DialogContent>

      <DialogActions>
        <Typography component='body2' variant='body2'>
          * Po uzavretí registrácie už nebudete môcť pridávať a upravovať
          žiakov.
        </Typography>
        <Button onClick={onClose}>Zrušiť</Button>

        <Button
          color='primary'
          disabled={
            !Boolean(
              (state.billingAddr.length >= 1 || checkedAddr) &&
                state.payment.length >= 1
            )
          }
          onClick={handleSubmit}
          size='small'
          style={{ color: 'white' }}
          variant='contained'
        >
          Uzavrieť registráciu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishRegistrationDialog;
