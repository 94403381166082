import 'firebase/auth';
import 'firebase/database';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const config = {
  apiKey: 'AIzaSyCHVzOqUsOcF5of8oDOZFx8b8DiJVywVss',
  appId: '1:835088091546:web:46ced44246463ea8bad223',
  authDomain: 'ypsilonsutaz.firebaseapp.com',
  databaseURL: 'https://ypsilonsutaz.firebaseio.com',
  messagingSenderId: '835088091546',
  projectId: 'ypsilonsutaz',
  storageBucket: 'ypsilonsutaz.appspot.com',
};

initializeApp(config);

export const auth = getAuth();
export const database = getDatabase();
