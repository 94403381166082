import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateStudent } from '../../../../redux/admin/admin.actions';

const INITIAL_STATE = {
  classroom: '',
  firstname: '',
  lastname: '',
  percentage: '',
  points: '',
};

const CLASSROOM_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const UpdateStudentDialog = ({ cid, onClose, open, sid, student }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState(INITIAL_STATE);

  const handleChange = useCallback(
    (key) => {
      return function ({ target: { value } }) {
        setState((state) => ({
          ...state,
          [key]: value,
        }));
      };
    },
    [setState]
  );

  function handleSubmit() {
    dispatch(updateStudent(cid, { ...state, sid }));
    onClose();
  }

  useEffect(() => {
    if (!Boolean(student)) {
      setState(INITIAL_STATE);
    } else if (!Boolean(student?.percentage) || !Boolean(student?.points)) {
      setState({ ...student, percentage: '', points: '' });
    } else {
      setState(student);
    }
  }, [open, student]);

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='xs'
      onClose={onClose}
      open={open}
    >
      <DialogTitle id='form-dialog-title'>Upraviť žiaka</DialogTitle>

      <DialogContent dividers>
        <TextField
          autoComplete='off'
          autoFocus
          fullWidth
          id='firstname'
          label='Meno'
          margin='dense'
          onChange={handleChange('firstname')}
          style={{ marginBottom: 20 }}
          value={state.firstname}
        />

        <TextField
          autoComplete='off'
          fullWidth
          id='lastname'
          label='Priezvisko'
          margin='dense'
          onChange={handleChange('lastname')}
          style={{ marginBottom: 20 }}
          value={state.lastname}
        />

        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <InputLabel id='select-classroom-label'>Ročník</InputLabel>

          <Select
            fullWidth
            id='select-classroom'
            labelId='select-classroom-label'
            onChange={handleChange('classroom')}
            value={state.classroom}
          >
            {CLASSROOM_VALUES.map((classroom) => (
              <MenuItem key={classroom} value={classroom}>
                {`${classroom}. ročník`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          autoComplete='off'
          fullWidth
          id='percentage'
          label='Percentá'
          margin='dense'
          onChange={handleChange('percentage')}
          style={{ marginBottom: 20 }}
          type='number'
          value={state.percentage}
        />

        <TextField
          autoComplete='off'
          fullWidth
          id='points'
          label='Body'
          margin='dense'
          onChange={handleChange('points')}
          style={{ marginBottom: 20 }}
          type='number'
          value={state.points}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Zrušiť</Button>

        <Button
          color='primary'
          disabled={
            !Boolean(
              state.classroom &&
                state.firstname.length >= 1 &&
                state.lastname.length >= 1
            )
          }
          onClick={handleSubmit}
          size='small'
          style={{ color: 'white' }}
          variant='contained'
        >
          Uložiť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStudentDialog;
