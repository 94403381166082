import { createAction } from '@reduxjs/toolkit';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from 'firebase/auth';
import { ref, set } from 'firebase/database';

import { auth, database } from '../../firebase.utils';
import { createNotification } from '../notifications/notifications.actions';

export const [
  getUserStarted,
  getUserReceived,
  getUserFailed,
  loginUserStarted,
  loginUserReceived,
  loginUserFailed,
] = [
  createAction('GET_USER_STARTED'),
  createAction('GET_USER_RECEIVED'),
  createAction('GET_USER_FAILED'),
  createAction('LOGIN_USER_STARTED'),
  createAction('LOGIN_USER_RECEIVED'),
  createAction('LOGIN_USER_FAILED'),
];

export const loginUser =
  ({ email, password }) =>
  (dispatch) => {
    dispatch(loginUserStarted());

    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        dispatch(
          loginUserReceived({
            email: user.user.email,
            name: user.user.displayName,
            uid: user.user.uid,
          })
        );
      })
      .catch((error) => {
        dispatch(loginUserFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [registerUserStarted, registerUserReceived, registerUserFailed] = [
  createAction('REGISTER_USER_STARTED'),
  createAction('REGISTER_USER_RECEIVED'),
  createAction('REGISTER_USER_FAILED'),
];

export const registerUser =
  ({
    city,
    email,
    firstname,
    lastname,
    name,
    password,
    phone,
    postalCode,
    schoolName,
    street,
    title,
  }) =>
  (dispatch) => {
    dispatch(registerUserStarted());
    createUserWithEmailAndPassword(auth, email, password)
      .then((user) => {
        dispatch(
          registerUserReceived({
            email: user.user.email,
            name: user.user.displayName,
            uid: user.user.uid,
          })
        );
        updateProfile(user.user, { displayName: name });
        // create user in db
        let cdate = new Date(Date.now());
        set(ref(database, 'koordinator/' + user.user.uid), {
          city: city,
          createdAt: cdate.toUTCString(),
          email: email,
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          postalCode: postalCode,
          schoolName: schoolName,
          street: street,
          title: title,
        });
      })
      .catch((error) => {
        dispatch(registerUserFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [logoutUserStarted, logoutUserReceived, logoutUserFailed] = [
  createAction('LOGOUT_USER_STARTED'),
  createAction('LOGOUT_USER_RECEIVED'),
  createAction('LOGOUT_USER_FAILED'),
];

export const logoutUser = () => (dispatch) => {
  dispatch(logoutUserStarted());
  signOut(auth)
    .then(() => {
      dispatch(logoutUserReceived());
    })
    .catch((error) => {
      dispatch(logoutUserFailed());
      dispatch(
        createNotification({
          message: `${error.code}: ${error.message}`,
          severity: 'error',
        })
      );
    });
};

export const [
  resetPasswordStarted,
  resetPasswordReceived,
  resetPasswordFailed,
] = [
  createAction('RESET_PASSWORD_STARTED'),
  createAction('RESET_PASSWORD_RECEIVED'),
  createAction('RESET_PASSWORD_FAILED'),
];

export const resetPassword =
  ({ email }) =>
  (dispatch) => {
    dispatch(resetPasswordStarted());
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          dispatch(resetPasswordReceived());
          resolve();
        })
        .catch((error) => {
          dispatch(resetPasswordFailed());
          dispatch(
            createNotification({
              message: `${error.code}: ${error.message}`,
              severity: 'error',
            })
          );
          reject();
        });
    });
  };
