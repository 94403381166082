import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  button: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  container: {
    boxShadow: '5px 5px 5px #888888',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '75%',
  },
  formInput: {
    marginBottom: '1rem',
  },

  logo: {
    marginTop: 30,
    maxWidth: '100%',
    width: 150,
  },
  registrationLinkGrid: {
    marginBottom: 50,
  },
  root: {
    background: '#f1f7fc',
    display: 'block',
    height: '80%',
    overflowY: 'scroll',
    padding: '5rem 0',
    position: 'fixed',
    textAlign: 'center',
    width: '100%',
  },
}));
