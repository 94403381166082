import './App.css';

import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref } from 'firebase/database';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { auth, database } from '../../firebase.utils';
import {
  getCoordinatorsReceived,
  getCoordinatorsStarted,
} from '../../redux/admin/admin.actions';
import { getUserReceived, getUserStarted } from '../../redux/auth/auth.actions';
import {
  getCoordinatorReceived,
  getCoordinatorStarted,
} from '../../redux/coordinator/coordinator.actions';
import { removeNotification } from '../../redux/notifications/notifications.actions';
import { selectNotifications } from '../../redux/notifications/notifications.selectors';
import { routes } from '../../utils';
import AuthenticatedRoute from '../AuthenticatedRoute/AuthenticatedRoute.component';
import Home from '../Home/Home.component';
import Login from '../Login/Login.component';
import NotificationContainer from '../Notification/Notification.container';
import PasswordReset from '../PasswordReset/PasswordReset.component';
import SignUp from '../SignUp/SignUp.component';
import SimpleBackdrop from '../SimpleBackdrop/SimpleBackdrop.component';
import UnauthenticatedRoute from '../UnauthenticatedRoute/UnauthenticatedRoute.component';

function App() {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);

  function handleNotificationDismiss(id) {
    dispatch(removeNotification(id));
  }

  useEffect(() => {
    dispatch(getUserStarted());
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        dispatch(getUserReceived(null));
      } else {
        dispatch(
          getUserReceived({
            email: user.email,
            name: user.displayName,
            uid: user.uid,
          })
        );
        dispatch(getCoordinatorStarted());
        const coordinatorRef = ref(database, 'koordinator/' + user.uid);
        onValue(coordinatorRef, (snapshot) => {
          const data = snapshot.val();
          dispatch(getCoordinatorReceived(data));
          if (data.admin) {
            dispatch(getCoordinatorsStarted());
            const coordinatorsRef = ref(database, 'koordinator/');
            onValue(coordinatorsRef, (snapshot) => {
              const data = snapshot.val();
              dispatch(getCoordinatorsReceived(data));
            });
          }
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      <Switch>
        <Redirect exact from='/' to={routes.byId.home.path} />

        <AuthenticatedRoute component={Home} path={routes.byId.home.path} />

        <UnauthenticatedRoute component={Login} path={routes.byId.login.path} />

        <UnauthenticatedRoute
          component={SignUp}
          path={routes.byId.signup.path}
        />

        <UnauthenticatedRoute
          component={PasswordReset}
          path={routes.byId.passwordReset.path}
        />
      </Switch>

      <NotificationContainer
        notifications={notifications}
        onClick={handleNotificationDismiss}
      />

      <SimpleBackdrop />
    </div>
  );
}

export default App;
