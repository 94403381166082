import { createReducer } from '@reduxjs/toolkit';

import {
  getUserReceived,
  loginUserReceived,
  logoutUserReceived,
  registerUserReceived,
} from './auth.actions';

const initialState = {
  user: null,
};

export default createReducer(initialState, {
  [getUserReceived](state, action) {
    state.user = action.payload;
  },
  [loginUserReceived](state, action) {
    state.user = action.payload;
  },
  [logoutUserReceived](state, action) {
    state.user = null;
  },
  [registerUserReceived](state, action) {
    state.user = action.payload;
  },
});
