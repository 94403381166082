import {
  Box,
  Button,
  Container,
  createTheme,
  IconButton,
  Paper,
  ThemeProvider,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DataGrid, skSK } from '@mui/x-data-grid';
import React from 'react';
import ReactExport from 'react-export-excel';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { reopenAll } from '../../redux/admin/admin.actions';
import {
  selectCoordinators,
  selectStatistics,
} from '../../redux/admin/admin.selectors';
import { routes } from '../../utils';
import useStyles from './Admin.styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcel = ({ dataset }) => {
  return (
    <ExcelFile
      element={
        <IconButton
          aria-label='download excel'
          color='primary'
          component='span'
        >
          <GetAppIcon />
        </IconButton>
      }
      filename='koordinatori'
    >
      <ExcelSheet data={dataset} name='Koordinator'>
        <ExcelColumn label='Titul' value='title' />

        <ExcelColumn label='Meno' value='firstname' />

        <ExcelColumn label='Priezvisko' value='lastname' />

        <ExcelColumn label='Email' value='email' />

        <ExcelColumn label='Telefon' value='phone' />

        <ExcelColumn label='Skola' value='schoolName' />

        <ExcelColumn label='Ulica' value='street' />

        <ExcelColumn label='Mesto' value='city' />

        <ExcelColumn label='PSC' value='postalCode' />

        <ExcelColumn
          label='Vytvorený'
          // eslint-disable-next-line react/jsx-no-bind
          value={(col) => new Date(col.createdAt)}
        />

        <ExcelColumn
          label='Fakturácia'
          // eslint-disable-next-line react/jsx-no-bind
          value={(col) =>
            Boolean(col.billing) ? col.billing.payment : 'Otvorena'
          }
        />

        <ExcelColumn
          label='Fakturacna adresa'
          // eslint-disable-next-line react/jsx-no-bind
          value={(col) => (Boolean(col.billing) ? col.billing.billingAddr : '')}
        />

        <ExcelColumn
          label='Počet žiakov'
          // eslint-disable-next-line react/jsx-no-bind
          value={(col) =>
            Boolean(col.students) ? Object.values(col.students).length : '0'
          }
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

const Admin = () => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const coordinators = useSelector(selectCoordinators);
  const statistics = useSelector(selectStatistics);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    skSK
  );

  const columns = [
    {
      field: 'createdAt',
      flex: 2,
      headerName: 'Registrovaný',
      minWidth: 150,
      type: 'dateTime',
      valueGetter: (params) => {
        return new Date(params.value);
      },
    },
    {
      field: 'fullName',
      flex: 3,
      headerName: 'Meno',
      minWidth: 150,
      valueGetter: (params) =>
        `${params.row.lastname} ${params.row.firstname} ${params.row.title}`,
    },
    //{ field: 'firstname', flex: 3, headerName: 'Meno', minWidth: 150 },
    //{ field: 'lastname', flex: 3, headerName: 'Priezvisko', minWidth: 150 },
    //{ field: 'schoolName', flex: 3, headerName: 'Škola', minWidth: 150 },
    { field: 'street', flex: 2.5, headerName: 'Ulica', minWidth: 150 },
    { field: 'city', flex: 2.5, headerName: 'Mesto', minWidth: 150 },
    { field: 'phone', flex: 2, headerName: 'Telefón', minWidth: 150 },
    { field: 'email', flex: 3.2, headerName: 'Email', minWidth: 170 },
    {
      field: 'count',
      flex: 1.6,
      headerName: 'Počet',
      minWidth: 70,
      valueGetter: (params) => {
        if (Boolean(params.row.students)) {
          return `${Object.values(params.row.students).length}`;
        } else return '0';
      },
    },
    {
      field: 'billingType',
      flex: 1.8,
      headerName: 'Typ',
      minWidth: 100,
      valueGetter: (params) => {
        if (Boolean(params.row.billing)) {
          return `${params.row.billing.payment}`;
        } else return 'otvorená';
      },
    },
  ];

  function handleRowClick(coordinatorId) {
    history.push(routes.byId.coordinatorDetail.generatePath(coordinatorId));
  }

  function handleReopenAll() {
    dispatch(reopenAll(coordinators));
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <div style={{ display: 'flex' }}>
        <Box component={Paper} style={{ flex: 1, maxWidth: 150, padding: 5 }}>
          <span>Počet uzavretých</span>

          <br />

          <span>1. ročník: {statistics.finished['1']}</span>

          <br />

          <span>2. ročník: {statistics.finished['2']}</span>

          <br />

          <span>3. ročník: {statistics.finished['3']}</span>

          <br />

          <span>4. ročník: {statistics.finished['4']}</span>

          <br />

          <span>5. ročník: {statistics.finished['5']}</span>

          <br />

          <span>6. ročník: {statistics.finished['6']}</span>

          <br />

          <span>7. ročník: {statistics.finished['7']}</span>

          <br />

          <span>8. ročník: {statistics.finished['8']}</span>

          <br />

          <span>9. ročník: {statistics.finished['9']}</span>

          <br />

          <span>Spolu: {statistics.finished['all']}</span>
        </Box>

        <Box
          component={Paper}
          style={{ flex: 1, marginLeft: 20, maxWidth: 150, padding: 5 }}
        >
          <span>Počet otvorených</span>

          <br />

          <span>1. ročník: {statistics.unfinished['1']}</span>

          <br />

          <span>2. ročník: {statistics.unfinished['2']}</span>

          <br />

          <span>3. ročník: {statistics.unfinished['3']}</span>

          <br />

          <span>4. ročník: {statistics.unfinished['4']}</span>

          <br />

          <span>5. ročník: {statistics.unfinished['5']}</span>

          <br />

          <span>6. ročník: {statistics.unfinished['6']}</span>

          <br />

          <span>7. ročník: {statistics.unfinished['7']}</span>

          <br />

          <span>8. ročník: {statistics.unfinished['8']}</span>

          <br />

          <span>9. ročník: {statistics.unfinished['9']}</span>

          <br />

          <span>Spolu: {statistics.unfinished['all']}</span>
        </Box>
      </div>

      <Box
        component={Paper}
        style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
      >
        <DownloadExcel
          dataset={
            coordinators
              ? Object.keys(coordinators)
                  .map((key) => ({
                    ...coordinators[key],
                    id: key,
                  }))
                  .filter((coordinator) => !coordinator.admin)
              : []
          }
        />

        <Button
          color='primary'
          disabled
          onClick={handleReopenAll}
          variant='text'
        >
          Otvoriť registráciu všetkým
        </Button>

        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            className={classes.table}
            columns={columns}
            disableSelectionOnClick
            // eslint-disable-next-line react/jsx-no-bind
            getRowClassName={(params) =>
              `theme--${params.row.finished ? 'Closed' : 'Open'}`
            }
            // eslint-disable-next-line react/jsx-no-bind
            onRowClick={(params, event) => {
              event.defaultMuiPrevented = true;
              handleRowClick(params.id);
            }}
            rows={
              coordinators
                ? Object.keys(coordinators)
                    .map((key) => ({
                      ...coordinators[key],
                      id: key,
                    }))
                    .filter((coordinator) => !coordinator.admin)
                : []
            }
          />
        </ThemeProvider>
      </Box>
    </Container>
  );
};

export default Admin;
