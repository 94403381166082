import { createSelector } from '@reduxjs/toolkit';

export const selectLoadingFlags = (actions) =>
  createSelector(
    (state) => state.loading,
    (loading) =>
      Object.keys(loading)
        .filter((action) => actions.includes(action))
        .map((action) => loading[action])
  );

export const selectLoadingFlagsReduced = (actions) =>
  createSelector(
    (state) => state.loading,
    (loading) =>
      Object.keys(loading)
        .filter((action) => actions.includes(action))
        .some((action) => loading[action])
  );
