import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { selectLoggedUser } from '../../redux/auth/auth.selectors';
import { routes } from '../../utils';

function AuthenticatedRoute({ appProps, component: C, ...rest }) {
  const user = useSelector(selectLoggedUser);
  return (
    <Route
      {...rest}
      // eslint-disable-next-line react/jsx-no-bind
      render={(props) =>
        Boolean(user) ? (
          <C {...props} {...appProps} />
        ) : (
          <Redirect to={routes.byId.login.generatePath()} />
        )
      }
    />
  );
}

export default AuthenticatedRoute;
