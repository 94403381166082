import { configureStore } from '@reduxjs/toolkit';

import admin from './admin/admin.reducer';
import auth from './auth/auth.reducer';
import coordinator from './coordinator/coordinator.reducer';
import loading from './loading/loading.reducer';
import notifications from './notifications/notifications.reducer';

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  reducer: {
    admin,
    auth,
    coordinator,
    loading,
    notifications,
  },
});

export { store };
