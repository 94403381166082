import { createSelector } from '@reduxjs/toolkit';

export const selectCoordinators = createSelector(
  (state) => state.admin,
  (admin) => admin.coordinators
);

export const selectStatistics = createSelector(
  (state) => state.admin,
  (admin) => admin.statistics
);
