import { createAction } from '@reduxjs/toolkit';
import { push, ref, remove, set, update } from 'firebase/database';

import { auth, database } from '../../firebase.utils';
import { createNotification } from '../notifications/notifications.actions';

export const [
  getCoordinatorStarted,
  getCoordinatorReceived,
  getCoordinatorFailed,
] = [
  createAction('GET_COORDINATOR_STARTED'),
  createAction('GET_COORDINATOR_RECEIVED'),
  createAction('GET_COORDINATOR_FAILED'),
];

export const [
  updateCoordinatorStarted,
  updateCoordinatorReceived,
  updateCoordinatorFailed,
] = [
  createAction('UPDATE_COORDINATOR_STARTED'),
  createAction('UPDATE_COORDINATOR_RECEIVED'),
  createAction('UPDATE_COORDINATOR_FAILED'),
];

export const updateCoordinator =
  ({
    city,
    firstname,
    lastname,
    phone,
    postalCode,
    schoolName,
    street,
    title,
  }) =>
  (dispatch) => {
    dispatch(updateCoordinatorStarted());
    const uid = auth.currentUser.uid;
    const updates = {};
    updates['/koordinator/' + uid + '/city'] = city;
    updates['/koordinator/' + uid + '/firstname'] = firstname;
    updates['/koordinator/' + uid + '/lastname'] = lastname;
    updates['/koordinator/' + uid + '/phone'] = phone;
    updates['/koordinator/' + uid + '/postalCode'] = postalCode;
    updates['/koordinator/' + uid + '/schoolName'] = schoolName;
    updates['/koordinator/' + uid + '/street'] = street;
    updates['/koordinator/' + uid + '/title'] = title;
    update(ref(database), updates)
      .then(() => {
        dispatch(updateCoordinatorReceived());
        dispatch(
          createNotification({
            message: `Zmeny uložené`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(updateCoordinatorFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [addStudentStarted, addStudentReceived, addStudentFailed] = [
  createAction('ADD_STUDENT_STARTED'),
  createAction('ADD_STUDENT_RECEIVED'),
  createAction('ADD_STUDENT_FAILED'),
];

export const addStudent =
  ({ classroom, firstname, lastname }) =>
  (dispatch) => {
    dispatch(addStudentStarted());
    const uid = auth.currentUser.uid;
    set(push(ref(database, '/koordinator/' + uid + '/students')), {
      classroom,
      firstname,
      lastname,
    })
      .then(() => {
        dispatch(addStudentReceived());
        dispatch(
          createNotification({
            message: `Žiak bol pridaný`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(addStudentFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [
  updateStudentStarted,
  updateStudentReceived,
  updateStudentFailed,
] = [
  createAction('UPDATE_STUDENT_STARTED'),
  createAction('UPDATE_STUDENT_RECEIVED'),
  createAction('UPDATE_STUDENT_FAILED'),
];

export const updateStudent =
  ({ classroom, firstname, lastname, sid }) =>
  (dispatch) => {
    dispatch(updateStudentStarted());
    const uid = auth.currentUser.uid;
    const updates = {};
    updates['/firstname'] = firstname;
    updates['/lastname'] = lastname;
    updates['/classroom'] = classroom;
    update(ref(database, '/koordinator/' + uid + '/students/' + sid), updates)
      .then(() => {
        dispatch(updateStudentReceived());
        dispatch(
          createNotification({
            message: `Žiak bol zmenený`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(updateStudentFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };

export const [
  removeStudentStarted,
  removeStudentReceived,
  removeStudentFailed,
] = [
  createAction('REMOVE_STUDENT_STARTED'),
  createAction('REMOVE_STUDENT_RECEIVED'),
  createAction('REMOVE_STUDENT_FAILED'),
];

export const removeStudent = (sid) => (dispatch) => {
  dispatch(removeStudentStarted());
  const uid = auth.currentUser.uid;
  remove(ref(database, '/koordinator/' + uid + '/students/' + sid))
    .then(() => {
      dispatch(removeStudentReceived());
      dispatch(
        createNotification({
          message: `Žiak bol odstránený`,
          severity: 'warning',
          timeout: 5000,
        })
      );
    })
    .catch((error) => {
      dispatch(removeStudentFailed());
      dispatch(
        createNotification({
          message: `${error.code}: ${error.message}`,
          severity: 'error',
        })
      );
    });
};

export const [
  finishRegistrationStarted,
  finishRegistrationReceived,
  finishRegistrationFailed,
] = [
  createAction('FINISH_REGISTRATION_STARTED'),
  createAction('FINISH_REGISTRATION_RECEIVED'),
  createAction('FINISH_REGISTRATION_FAILED'),
];

export const finishRegistration =
  ({ billingAddr, email, payment }) =>
  (dispatch) => {
    dispatch(finishRegistrationStarted());
    const uid = auth.currentUser.uid;
    const updates = {};
    updates['/finished'] = true;
    updates['/billing/billingAddr'] = billingAddr;
    updates['/billing/finished'] = true;
    updates['/billing/email'] = email;
    updates['/billing/payment'] = payment;
    update(ref(database, '/koordinator/' + uid), updates)
      .then(() => {
        dispatch(finishRegistrationReceived());
        dispatch(
          createNotification({
            message: `Registrácia bola uzavretá`,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(finishRegistrationFailed());
        dispatch(
          createNotification({
            message: `${error.code}: ${error.message}`,
            severity: 'error',
          })
        );
      });
  };
