// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, { type }) => {
  const matches = /(.*)_(STARTED|RECEIVED|FAILED)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'STARTED',
  };
};
